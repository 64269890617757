import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import './styles/base/__globalStyles.scss';
import './styles/base/_fonts.scss';
import './styles/base/_typography.scss';
import './styles/base/normalize.scss';

import React, { useState, useCallback, useEffect } from 'react';
import Preloader from 'Components/PreLoader/Preloader';
import AIRTool from 'Containers/AITools/AIRTool';
import AITool from 'Containers/AITools/AITool';
import ContentPlanner from 'Containers/Content Planner/ContentPlanner';
import DocumentView from 'Containers/DocumentView/DocumentView';
import FreeStyleEditor from 'Containers/FreeStyleEditor/FreeStyleEditor';
import ResetPasswordModal from 'Containers/Modal/ResetPasswordModal/ResetPasswordModal';
import Documents from 'Containers/Documents/Documents';
import Questionnaire from 'Containers/Questionnaire/Questionnaire';
import Team from 'Containers/Team/Team';
import TeamDetail from 'Containers/TeamDetail/TeamDetail';
import Templates from 'Containers/Templates/Templates';
import Projects from 'Components/Projects/Projects';
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout';
import BillingScreen from 'Containers/Subscription/BillingScreen';
import ConfirmSubscription from 'Containers/Subscription/ConfirmSubscription';
import Subscription from 'Containers/Subscription/Subscription';
import { actions } from 'redux/slices/auth';
import { getCurrentSubscription } from 'services/subscriptionService';
import { NewUserSubscriptionPlan } from 'Containers/NewUserSubscriptionPlan/NewUserSubscriptionPlan';
import {
  CONTENT_PLANNER,
  EDITOR,
  FOLDER,
  FREESTYLE_EDITOR,
  DOCUMENT,
  DOCUMENTS,
  QUESTIONNAIRE,
  RESEARCH,
  SUMMARIZATION,
  TEAMS,
  TEMPLATES,
  TERMS,
  PRIVACY,
  DOCUMENT_HISTORY,
  PROJECTS,
  BILLING_SUBSCRIPTION,
  BILLING_PAYMENT,
  CONFIRM_SUBSCRIPTION,
  SUBSCRIBE_PLAN,
  SUBSCRIPTION,
  UPDATE_PROFILE,
  SUBSCRIPTION_PLAN,
  BILLING_PLAN,
  ONBOARDING,
  NEW_PLAN,
  BRAND,
  INTEGRATION,
} from './constants';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Editor from './Containers/Editor';
import LandingPage from 'Components/LandingPage/LandingPage';
import DocumentHistory from 'Containers/DocumentHistory/DocumentHistory';
import EmailVerification from 'Containers/EmailVerification/EmailVerification';
import InviteAccept from 'Containers/InviteAccept/InviteAccept';
import ProjectDetail from 'Components/Projects/ProjectDetail';
import Ideate from 'Containers/Ideate/Ideate';
import Onboarding from 'Containers/Onboarding/Onboarding';
import Step1 from 'Containers/Onboarding/Step1';
import TermsConditions from 'Containers/TermsConditions';
import PrivacyPolicy from 'Containers/PrivacyPolicy';
import ProfileSetup from 'Containers/Profile/ProfileSetup';

import Hotjar from '@hotjar/browser';
import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';

import { ROUTES } from 'constants/routes';
import AIProjectPlan from './Components/Projects/ProjectPlan/AIProjectPlan';
import AIProject from 'Components/Projects/AIProject/AIProject';
import AIProjectSetup from 'Components/Projects/AIProject/Setup/AIProjectSetup';
import Dashboard from 'Containers/Dashboard/Dashboard';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { error as errorToast } from 'helpers/toaster';
import PaymentScreen from 'Containers/Subscription/PaymentScreen';
import PlanDetailsScreen from 'Containers/Subscription/PlanDetailsScreen';
import LoginPage from 'Containers/Auth/LoginPage';
import SignUp from 'Containers/Auth/SignUp';
import OnboardingFlow from 'Containers/OnboardingFlow/OnboardingFlow';
import Brand from 'Containers/Brand/Brand';
import Integrations from 'Containers/Integrations/Integrations';
import MetaPixel from 'Components/MetaPixel/MetaPixel';

const ProtectedRoute = ({ user, redirectPath = '/' }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const fetchCurrentSubscription = useCallback(async () => {
    try {
      const currentSubscriptionRes = await getCurrentSubscription();
      const { data: subscriptionData } = currentSubscriptionRes || {};
      if (subscriptionData) {
        dispatch(
          actions.updateUser({ user: { subscriptions: subscriptionData } }),
        );
      }
    } catch (error) {}
  }, [dispatch]);

  useEffect(() => {
    fetchCurrentSubscription();
  }, [fetchCurrentSubscription]);

  if (!user?.isLoggedIn) {
    return <Navigate to={redirectPath} replace />;
  }

  const loggedInUser = user?.user || {};

//   if (pathname.includes(ONBOARDING)) {
//     debugger
// // incase of current_subscription and  active, pass_due,trial allow dashboard
// // incase of current_subscription incomplete, incomplete-expired, unpaid, cancel restrict to payment screen
//     if (loggedInUser?.subscriptions?.current_subscription ) {
//       return <Navigate to="/" replace />;
//     }
//     if(!loggedInUser?.subscriptions?.current_subscription && loggedInUser?.subscriptions?.last_subscription?.status === "open" ){
//       return <Navigate to={`/payment?subscriptionId=${loggedInUser?.subscriptions?.last_subscription?.stripe_price?.id}&overdue=true`} replace />;
//     }
//     return <Outlet />;
//   }


if (pathname.includes(ONBOARDING)) {
  let active= loggedInUser?.subscriptions?.current_subscription?.active;
  let status= loggedInUser?.subscriptions?.current_subscription?.status;
  let laststatus= loggedInUser?.subscriptions?.last_subscription?.status;

// incase of current_subscription and  active, pass_due,trial allow dashboard
// incase of current_subscription incomplete, incomplete-expired, unpaid, cancel restrict to payment screen
  if (loggedInUser?.subscriptions?.current_subscription && active && (status === 'active' || status === 'past_due' || status === 'trial' || status === 'canceled') ) {
    return <Navigate to="/" replace />;
  }
  if(!loggedInUser?.subscriptions?.current_subscription &&  loggedInUser?.subscriptions?.last_subscription && loggedInUser?.subscriptions?.last_subscription.status !== "trial" ){
    return <Navigate state={{status:loggedInUser?.subscriptions?.last_subscription?.status, subscriptionId: loggedInUser?.subscriptions?.last_subscription?.stripe_price?.id }} to={`/payment`} replace />;
  }
  if(loggedInUser?.subscriptions?.current_subscription && !active &&    (status === 'canceled' || status === 'incomplete_expired' || status === 'incomplete'  || status === 'unpaid')){
    return <Navigate  state={{status: status, subscriptionId: loggedInUser?.subscriptions?.current_subscription?.stripe_price?.id }} to={`/payment`} replace />;
  }
  return <Outlet />;
}

  const { subscriptions } = loggedInUser || {};
  const { current_subscription: currentSubscription } = subscriptions || {};
  const hasActiveSubscription =
    currentSubscription && currentSubscription?.active;

  if (pathname.includes(SUBSCRIBE_PLAN)) {
    if (hasActiveSubscription) {
      return <Navigate to={'/'} replace />;
    }
    return <Outlet />;
  }

  // add   !pathname.includes(SUBSCRIBE_PLAN || SUBSCRIPTION) && this might fix on cancel redirect to onboarding
  // !hasCancelSubscription (this will take user to / on login after cancel subscription)
  if (
    loggedInUser &&
    !pathname.includes(SUBSCRIBE_PLAN) &&
    !pathname.includes(CONFIRM_SUBSCRIPTION) &&
    !pathname.includes('/resubscribe-confirmation') &&
    !hasActiveSubscription
  ) {
    return <Navigate to={ONBOARDING} replace />;
  }
  return <Outlet />;
};

if (process.env.NODE_ENV === 'production') {
  // HOTJAR:
  const siteId = 3622637;
  const hotjarVersion = 6;
  Hotjar.init(siteId, hotjarVersion);

  // GOOGLE TAG MANAGER:
  const containerId = process.env.REACT_APP_GTM_CONTAINER_ID;
  if (containerId) {
    Analytics({
      plugins: [
        googleTagManager({
          containerId,
        }),
      ],
    });
  }
}

const useMetaPixel = () => {
  const location = useLocation();
  useEffect(() => {
    window.fbq('track', 'PageView');
  }, [location]);
};

function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // staleTime: 1000 * 60 * 2, // 2 minutes
            staleTime: 15000, // 15 seconds TODO: this is temporary while react-query is not implemented in every place of the project
          },
        },
        queryCache: new QueryCache({
          onError: (error) => {
            errorToast(error);
          },
        }),
      }),
  );
  const user = useSelector((state) => state.auth);
  const { subscriptions } = user ?? {};
  const { current_subscription: currentSubscriptionPlan } = subscriptions ?? {};

  if (process.env.NODE_ENV === 'production') {
    if (user?.isLoggedIn) {
      Hotjar.identify(user.id, {
        name: user.name,
        email: user.email,
        role: user.role,
        company: user.company,
        sign_in_count: user.sign_in_count,
        last_active_at: user.last_active_at,
      });
    }
  }
  // useMetaPixel();

  return (
    <div className="App">
      <MetaPixel />
      <QueryClientProvider client={queryClient}>
        <Preloader />
        <DashboardLayout data={user}>
          <Routes>
            {!user?.isLoggedIn && <Route index element={<LandingPage />} />}

            <Route
              path={SUBSCRIPTION_PLAN}
              element={<NewUserSubscriptionPlan />}
            />
            <Route
              path={'log-in'}
              element={!user?.isLoggedIn ? <LoginPage /> : <Navigate to="/" />}
            />
            <Route
              path={'sign-up'}
              element={!user?.isLoggedIn ? <SignUp /> : <Navigate to="/" />}
            />
            <Route
              path={'/payment'}
              element={
                user && user.isLoggedIn ? (
                  <PaymentScreen />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path={`summary/plan/:planName/price/:price/type/:type`}
              element={
                user && user.isLoggedIn ? (
                  <ConfirmSubscription />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            <Route
              path={`resubscribe-confirmation/plan/:planName/price/:price/type/:type`}
              element={
                user && user.isLoggedIn ? (
                  <ConfirmSubscription />
                ) : (
                  <Navigate to="/" />
                )
              }
            />

            <Route path={TERMS} element={<TermsConditions />} />
            <Route path={PRIVACY} element={<PrivacyPolicy />} />
            <Route path={EDITOR} element={<Editor />} />
            <Route element={<ProtectedRoute user={user} />}>
              <Route
                path={ONBOARDING}
                element={
                  user && !user.is_onboarded ? (
                    <OnboardingFlow />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />

              <Route path={NEW_PLAN} element={<OnboardingFlow />} />

              <Route
                index
                element={user?.isLoggedIn ? <Dashboard /> : <LandingPage />}
              />
              <Route
                path={`${EDITOR}/document/:documentId/template/:templateId/:shouldCopy?/:access?`}
                element={<Editor />}
              />
              {/* <Route path={'onboarding'} element={<OnboardingFlow />} /> */}

              <Route
                path={`${EDITOR}/document/:documentId/template/:templateId/:shouldCopy?/:access?`}
                element={<Editor />}
              />
              <Route path={`document/:documentId`} element={<DocumentView />} />
              <Route
                path={`${DOCUMENT_HISTORY}/:documentId`}
                element={<DocumentHistory />}
              />
              <Route
                path={`${FREESTYLE_EDITOR}/document/:documentId/:shouldCopy?/:access?`}
                element={<FreeStyleEditor />}
              />
              <Route path={CONTENT_PLANNER} element={<ContentPlanner />} />
              <Route path={DOCUMENTS} element={<Documents />} />
              <Route
                path={`${QUESTIONNAIRE}/template/:templateId`}
                element={<Questionnaire />}
              />
              <Route
                path={`${CONTENT_PLANNER}/brief/:briefId`}
                element={<ContentPlanner />}
              />
              <Route path={DOCUMENT} element={<Documents />} />
              <Route
                path={FOLDER + '/:folderSlug/:shouldCopy?/:access?'}
                element={<Documents />}
              />
              <Route
                path={DOCUMENT + '/:documentType'}
                element={<Documents />}
              />
              <Route path={TEAMS} element={ <Team />} />
              <Route path={`${TEAMS}/:teamId`} element={<TeamDetail />} />
              <Route path={ROUTES.Ideate} element={<Ideate />} />
              <Route path={SUMMARIZATION} element={<AITool />} />
              <Route path={RESEARCH} element={<AIRTool /> } />
              <Route path={TEMPLATES} element={<Templates />} />
              <Route path={BRAND} element={<Brand />} />
              <Route path={INTEGRATION} element={<Integrations />} />

              <Route path={ROUTES.Projects} element={<Projects />} />
              <Route path={ROUTES.Project.New} element={<AIProject />} />
              <Route path={ROUTES.Project.Setup} element={<AIProjectSetup />} />
              <Route
                path={ROUTES.Project.NewPlan(':projectId')}
                element={<AIProjectPlan />}
              />
              <Route
                path={`${PROJECTS}/:projectId`}
                element={<ProjectDetail />}
              />
              <Route
                path={`${PROJECTS}/:projectId/:projectType`}
                element={<ProjectDetail />}
              />
              <Route
                path={`${PROJECTS}/:projectId/folders/:folderSlug`}
                element={<ProjectDetail />}
              />
              {/* <Route path="/onboarding" element={<Onboarding />} /> */}
              {/* <Route path="/onboarding/info" element={<Step1 />} /> */}
              <Route path={SUBSCRIPTION} element={<Subscription />} />
              <Route
                path={`${CONFIRM_SUBSCRIPTION}/plan/:planName/price/:price/type/:type`}
                element={<ConfirmSubscription />}
              />
              <Route path={BILLING_SUBSCRIPTION} element={<BillingScreen />} />
              <Route path={BILLING_PAYMENT} element={<PaymentScreen />} />
              <Route path={BILLING_PLAN} element={<PlanDetailsScreen />} />

              <Route
                path={SUBSCRIBE_PLAN}
                element={
                  <div style={{ marginLeft: 30, marginRight: 30 }}>
                    <Subscription />
                  </div>
                }
              />
              <Route
                path={`${UPDATE_PROFILE}/:form`}
                element={<ProfileSetup />}
              />
            </Route>
            <Route path="/resetPassword" element={<ResetPasswordModal />} />
            <Route path="/signup" element={<LandingPage />} />
            <Route
              path="/email_verfication/:token"
              element={<EmailVerification />}
            />
            <Route
              path="/user_invites/:token/accept"
              element={<InviteAccept />}
            />
            <Route path="*" element={<p>There's nothing here: 404!</p>} />
          </Routes>
        </DashboardLayout>
        {process.env.NODE_ENV === 'development' && (
          <ReactQueryDevtools
            initialIsOpen={false}
            buttonPosition="bottom-left"
          />
        )}
      </QueryClientProvider>
    </div>
  );
}

export default App;
